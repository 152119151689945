import group from "../assets/images/Group 111.png";

import VerticalGraph1 from "../assets/services/vertical-graph-1.svg";
import VerticalGraph2 from "../assets/services/vertical-graph-2.svg";

export default function ServiceBanner({ background, serviceName, children }) {
  return (
    <main
      className="relative bg-cover bg-center flex"
      style={{
        backgroundImage: `url(${background})   `,
        // opacity: "0.9",
        height: "86vh",
        backgroundSize: "cover, cover", // Adjust as needed
        backgroundPosition: "center, center", // Adjust as needed
        backgroundRepeat: "no-repeat, no-repeat",
      }}
    >
      <div style={{ height: "100%" }} className="mask-group"></div>
      <img
        className="electro-image hidden md:block"
        style={{ position: "absolute", right: "0", zIndex: "2" }}
        src={group}
        alt=""
      />
      <img
        className="electro-image-1 hidden md:hidden"
        style={{ position: "absolute", left: "0", bottom: "0" }}
        src={group}
        alt=""
      />
      <img
        className="md:hidden absolute top-0 right-0 z-10"
        src={VerticalGraph1}
        alt=""
      />
      <img
        className="md:hidden absolute bottom-0 left-0 z-10"
        src={VerticalGraph2}
        alt=""
      />
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative electro-heading z-[2] text-white p-4 md:p-8 lg:p-8 text-left lg:mt-auto lg:mb-[40px]">
        <h1
          style={{ fontSize: "23px", fontFamily: "AreaNormalTrialBlack" }}
          className="it-title md:ml-8 lg:ml-16"
        >
          {serviceName}
        </h1>
        <p
          style={{ fontSize: "30.9px", fontFamily: "AreaNormalTrialRegular" }}
          className="mt-2 it-des italic md:ml-8 lg:ml-16  align-left"
        >
          {children}
        </p>
      </div>
    </main>
  );
}
