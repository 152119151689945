import { H2 } from "./Section";

import Step0Img from "../assets/images/58321ce6-8d4c-45e2-81bc-62e34d92fbb3.png";
import Step1Img from "../assets/images/44c9327c-9d14-4df3-a47f-00ddb5ed9470.png";
import Step2Img from "../assets/images/7da6e827-b1c5-4015-af96-3fb55d32067a.png";
import Step3Img from "../assets/images/768dd9e1-1c20-44db-967c-97cc2fde12a6.png";
import Step4Img from "../assets/images/18ed3517-0b84-49c5-8a1d-cfc290ada66a.png";

import Img1 from "../assets/images/4277b35d02daa60ee82f71ec63604efe9479e03b.png";
import Img2 from "../assets/images/fa1daacf86b6e9eebd412fdfa3054e5781392978.png";
import Img3 from "../assets/images/4406120576a0eb9a3ef052dcb16936ecfc6c7c95.png";
import ArrowBTR from "../assets/images/Vector47.png";

export function SliderSection({ heading, children, className }) {
  return (
    <div className={`w-full relative overflow-hidden my-8 ${className?.contaner}`}>
      <div className={`mx-auto md:w-[85%] ${className?.subSection}`}>
        <H2 className={`my-auto pl-8 md:pl-16 md:pb-4 ${className?.heading}`}>{heading}</H2>
        {children}
      </div>
    </div>
  );
}

function ExpertiseCard({ children, className }) {
  return (
    <div
      class={`flex w-[43.5%] h-[85px] px-2 mt-6 text-center text-[0.706rem] leading-[0.714rem] bg-white rounded-[10.2px] shadow-mining md:w-[31%] xl:h-[143px] xl:w-[22%] xl:text-[1.149rem] xl:leading-[1.191rem] ${className}`}
    >
      <span className="m-auto text-ANL-EB ">{children}</span>
    </div>
  );
}

// const expertiseData = [
//   'Strategic Mining Planning',
//   'Mining Methods Optimization',
//   'Quantitative and Qualitative Risk Analysis',
//   'Specialized report: \n• Jorc \n• S-K1300 \n• NI 43-101',
//   'Project Evaluation (Support in Geology - Geometallurgy and Mining)',
//   'Mineral Resource and Reserve Estimation',
//   'Software support and implementation',
//   'Conceptual, Prefeasibility and Feasibility Studies, Due Diligence and Audits',
//   'Mining GIS Modeling and Geomatics',
// ];

export function Expertise({ className }) {
  return (
    <div
      className={`w-full flex flex-wrap lg:my-8 lg:mx-auto lg:w-[80%] ${className}`}
    >
      <ExpertiseCard className={"ml-auto"}>
        Strategic Mining Planning
      </ExpertiseCard>
      <ExpertiseCard className={"ml-auto"}>
        Mining Methods Optimization
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto md:ml-auto md:mr-0"}>
        Quantitative and Qualitative Risk Analysis
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto lg:ml-auto lg:mr-0"}>
        Specialized report:
        <br />
        <span className="text-ANL-R">
          Jorc
          <br />
          SK1300
          <br />
          NI 43-101
        </span>
      </ExpertiseCard>

      <ExpertiseCard className={"ml-auto lg:mr-auto lg:ml-0 xl:-ml-[40px]"}>
        Project Evaluation (Support in Geology - Geometallurgy and <br />
        Mining)
      </ExpertiseCard>
      <ExpertiseCard className={"ml-auto md:mr-auto md:ml-0"}>
        Mineral Resource and Reserve Estimation
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto"}>
        Software support and implementation
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto"}>
        Conceptual, Prefeasibility and Feasibility Studies, Due Diligence and
        Audits
      </ExpertiseCard>

      <ExpertiseCard className={"ml-auto"}>
        Mining GIS Modeling and Geomatics
      </ExpertiseCard>
      <ExpertiseCard className={"ml-auto"}>
        Software support and implementation
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto md:ml-auto md:mr-0"}>
        Conceptual, Prefeasibility and Feasibility Studies, Due Diligence and
        Audits
      </ExpertiseCard>
      <ExpertiseCard className={"mr-auto md:ml-auto md:mr-0 lg:-mr-[40px]"}>
        Mining GIS Modeling and Geomatics
      </ExpertiseCard>
    </div>
  );
}

function DevelopmentStep({ icon, description }) {
  return (
    <div class="w-full aspect-square p-2 flex flex-col rounded-[8px] md:p-6 md:rounded-[21px] bg-white shadow-md">
      <img src={icon} className="w-[33px] md:w-[86px] m-auto" alt="dev-1" />
      <span class="text-center text-ANL-R text-[8.95px] m-auto leading-[9.27px] sm:text-[1.443rem] sm:leading-[1.495rem]">
        {description}
      </span>
    </div>
  );
}

function ArrowForward() {
  return (
    <div className="w-full flex justify-end">
      <img src={ArrowBTR} className="w-1/2 h-1/2" alt="arrow" />
    </div>
  );
}

function FlowRow({ children }) {
  return (
    <div className="w-full grid grid-cols-3 gap-4 lg:gap-10">{children}</div>
  );
}

export function DevelopmentFlow() {
  return (
    <div className="flex flex-col gap-4 w-full p-4 lg:w-[92%] lg:gap-10 mx-auto">
      <FlowRow>
        <DevelopmentStep
          icon={Img1}
          description={
            "Provision of software and support for the acquisition of licences"
          }
        />
      </FlowRow>
      <FlowRow>
        <ArrowForward />
        <DevelopmentStep
          icon={Img2}
          description={
            "On-line consulting and support of the applications under concession"
          }
        />
      </FlowRow>
      <FlowRow>
        <div></div>
        <ArrowForward />
        <DevelopmentStep
          icon={Img3}
          description={
            "Contribution to new developments and applications in mining"
          }
        />
      </FlowRow>
    </div>
  );
}

function AnalysisCard({ children }) {
  return (
    <div className="w-[123px] h-[65px] flex p-2 rounded-[8px] lg:p-6 bg-white shadow-md lg:w-[265px] lg:h-[143px] lg:rounded-[17px]">
      <span className="text-center text-ANL-B text-[0.533rem] leading-[0.552rem] lg:text-[1.149rem] lg:leading-[1.191rem] m-auto">
        {children}
      </span>
    </div>
  );
}

export function Analysis() {
  return (
    <div className="flex flex-col mt-4 gap-4 lg:mt-8 w-full mx-auto md:w-[70%] lg:w-[80%] lg:gap-8">

      <div className="w-[70%] mx-auto flex justify-between">
        <AnalysisCard>
          Modeling of factors affecting the mining plan
        </AnalysisCard>
        <img className="w-[33.5px] lg:w-fit h-fit my-auto" src={Step0Img} alt="1" />
        <AnalysisCard>
          {" "}
          Risk estimation and degree of reliability of the mining plan
        </AnalysisCard>
      </div>

      <div className="w-[75%] mx-auto flex justify-between">
        <img className="w-[33.5px] lg:w-fit h-fit" src={Step1Img} alt="2" />
        <img className="w-[33.5px] lg:w-fit h-fit" src={Step2Img} alt="3" />
      </div>

      <div className="flex justify-between">
        <AnalysisCard>
          Modeling of factors affecting the mining plan
        </AnalysisCard>
        <AnalysisCard>
          Risk estimation and degree of reliability of the mining plan
        </AnalysisCard>
      </div>

      <div className="w-[50%] mx-auto flex justify-between">
        <img src={Step4Img} className="w-[33.5px] lg:w-fit h-fit" alt="3" />
        <img src={Step3Img} className="w-[33.5px] lg:w-fit h-fit" alt="2" />
      </div>

      <div className="flex justify-center">
        <AnalysisCard>
          Construction of the mathematical model for quantitative rish analysis
        </AnalysisCard>
      </div>
    </div>
  );
}
