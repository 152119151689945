import group from "../assets/images/Group 111.png";

import VerticalGraph1 from "../assets/services/vertical-graph-1.svg";
import VerticalGraph2 from "../assets/services/vertical-graph-2.svg";

export function IntroContent({ children, className }) {
  return (
    <div
      className={`relative z-10 text-white text-left p-4 mt-auto md:ml-16 md:p-8 lg:p-8 ${className}`}
    >
      {children}
    </div>
  );
}

export default function IntroBanner({ background, children, home }) {
  return (
    <main
      className="relative flex flex-col bg-cover pb-[40px] backgroun-image-all bg-center"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <img
        className={`${
          home ? "" : "hidden md:block"
        } absolute z-10 w-[80%] right-0 aspect-auto ml-auto !h-fit mt-4 md:w-auto`}
        src={group}
        alt=""
      />
      {!home && (
        <img
          className="md:hidden absolute top-0 right-0 z-10"
          src={VerticalGraph1}
          alt=""
        />
      )}
      {!home && (
        <img
          className="md:hidden absolute bottom-0 left-0 z-10"
          src={VerticalGraph2}
          alt=""
        />
      )}
      <div className="mask-group !h-full"></div>
      {/*<div className="absolute inset-0 z-[1] bg-black opacity-50"></div>*/}
      {/*MAIN CONTENT */}
      {children}
    </main>
  );
}
