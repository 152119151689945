import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./Header.css";

import VerticalGraph1 from "../../assets/services/vertical-graph-1.svg";
import VerticalGraph2 from "../../assets/services/vertical-graph-2.svg";

import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for menu toggle
import Footer, { SocialIcon } from "../Footer";

const navs = [
  {
    name: "Services",
    path: "/services",
  },
  {
    name: "About us",
    path: "/about-us",
  },
  {
    name: "Contact us",
    path: "/contact-us",
  },
];

function Logo() {
  return (
    <div className="clip-path-group">
      <div className="group">
        <div className="clip-path-group-1">
          <div className="group-2">
            <div className="clip-path-group-3">
              <div className="group-4">
                <Link to="/">
                  <div className="rectangle-5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Logo or additional elements */}
    </div>
  );
}

function NavLink({ name, path, select, active, view }) {
  const className = useMemo(() => {
    if (view === "mobile") {
      return `mobile-nav-item ${active ? "mobile-nav-item-active " : ""}`;
    } else {
      return `nav-item ${active ? "nav-item-active" : ""}`;
    }
  }, [view, active]);
  return (
    <Link
      to={path}
      className={`whitespace-nowrap ${className}`}
      onClick={select}
    >
      <span>{name}</span>
    </Link>
  );
}

function MobileMenu({ selected, select }) {
  return (
    <div className="flex flex-col fixed z-40 left-0 bottom-0 w-full h-[calc(100%_-_75px)] bg-[#191919]">
      <div className="flex-1  flex flex-col">
        {navs.map((nav, index) => {
          return (
            <NavLink
              key={index}
              view="mobile"
              name={nav.name}
              path={nav.path}
              active={nav.path === selected}
              select={() => select(nav.path)}
            />
          );
        })}
        <div className="mobile-nav-item relative z-20">
          <SocialIcon iconClassName={"!text-[25px]"} />
        </div>
       
      </div>
      <Footer />
      <img
          className="md:hidden absolute top-0 right-0 z-10"
          src={VerticalGraph1}
          alt=""
        />
        <img
          className="md:hidden absolute bottom-0 left-0 z-10"
          src={VerticalGraph2}
          alt=""
        />
    </div>
  );
}

const Header = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const handleSelect = (path) => {
    setSelected(path);
    setIsMenuOpen(false); // Close menu after selecting an option
  };

  const pageName = useMemo(() => {
    return navs.find((nv) => nv.path === selected)?.name || "";
  }, [selected]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="w-full h-[75px] md:h-[110px] flex flex-col bg-white md:px-24">
      <div className="header">
        {/**LOGO*/}
        <Logo />
        <div className={`frame-6 menu-closed`}>
          {navs.map((nav, index) => {
            return (
              <NavLink
                key={index}
                name={nav.name}
                path={nav.path}
                active={nav.path === selected}
                select={() => handleSelect(nav.path)}
              />
            );
          })}
        </div>
        <Link
          to="/"
          onClick={() => handleSelect("")}
          className="w-[102px] mr-auto md:m-0 md:w-auto"
        >
          {" "}
          <div className="group-8" />{" "}
        </Link>
        <button
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <FaTimes size={22} />
          ) : (
            <div className="flex gap-4">
              <span className="nav-item !p-0 !my-auto">{pageName}</span>
              <FaBars size={22} />
            </div>
          )}
        </button>
      </div>
      {isMenuOpen && <MobileMenu selected={selected} select={handleSelect} />}
    </div>
  );
};

export default Header;
