import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/ServiceSections';
import Minning from './pages/Mining/Minning';
import Electromachanical from './pages/Electromechanical/Electromachanical';
import IT from './pages/IT';
import Consulting from './pages/Consulting/Consulting';
import Contactus from './pages/Contactus';
import AboutUs from './pages/Aboutus';

function App() {
  
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen !bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/minning" element={<Minning />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/it" element={<IT />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/electro-mechanics" element={<Electromachanical />} />


        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
