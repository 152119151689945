import React, { Fragment } from "react";
import "./Electromachanical.css";

import background from "../../assets/images/electro.png";
// Import custom CSS for blurring effect
import ecard1 from "../../assets/images/ecard1.png";
import ecard2 from "../../assets/images/ecard2.png";
import ecard3 from "../../assets/images/ecard3.png";
import ecard4 from "../../assets/images/ecard4.png";
import ecard5 from "../../assets/images/ecard5.png";
import ecard11 from "../../assets/images/ecard11.png";
import ecard22 from "../../assets/images/ecard22.png";
import ecard33 from "../../assets/images/ecard33.png";
import ecard44 from "../../assets/images/ecard44.png";
import ecard55 from "../../assets/images/ecard55.png";

import ServiceBanner from "../../components/ServiceBanner";
import { DesktopCards, MobileCards } from "../../components/Cards";
import { SliderWrapper } from "../../components/Slider";
import {
  BreadCrumb,
  GraphRight,
  H2,
  ServiceSection,
} from "../../components/Section";
import { SliderSection } from "../../components/Mining";

const serviceSectionsData = [
  {
    title: "Quality Control Management",
    items: [
      "Establishment and/or validation of criteria and standards for the design, manufacture, installation and maintenance of GMD systems for mill drives, installation, operation and maintenance of GMD systems for drives.",
      "Elaboration, review and supervision of the QA/QC plan, pre-commissioning and commissioning.",
    ],
  },
  {
    title: "Specialized Technical Support for Procure and Logistic",
    items: [
      "Comparative analysis of Gearless Mill Drive technologies for purchase decision.",
      "Comparative analysis of GMD technologies for purchase decision and elaboration of bidder qualification matrix.",
      "Conformity of components, special tools and materials prior to packing release.",
    ],
  },
  {
    title: "Installation, Testing and Start-up",
    items: [
      "Preparation, review and supervision of the Project Execution Plan.",
      "Planning and supervision during transportation and handling of main component of GMD type engines.",
    ],
  },
  {
    title: "Maintenance",
    items: [
      "Maintenance strategy for critical inspections and monitoring conditions (winding diagnostics, partial discharge test, etc).",
      "Premature failure risk assessment: recommended controls, protection and monitoring of controls, protection and monitoring of operational conditions.",
    ],
  },
];

const Electromachanical = () => {
  const Solutions = [
    {
      title: "Private Power Agreement - PPAs",
      description:
        "A long-term contract for purchasing electricity between a power provider and a customer.",
      imageUrl: ecard1, // Replace with actual image path
    },
    {
      title: "Electrical Infrastructure Planning",
      description:
        "Strategic development of electrical systems to meet current and future energy needs.",
      imageUrl: ecard2, // Replace with actual image path
    },
    {
      title: "Electrical Studies",
      description:
        "Analytical assessments of electrical systems to ensure safety, reliability, and efficiency.",
      imageUrl: ecard3, // Replace with actual image path
    },
    {
      title: "Substation Design",
      description:
        "Engineering and planning of electrical substations to manage and distribute power efficiently.",
      imageUrl: ecard4, // Replace with actual image path
    },
    {
      title: "Transmission Lines Design",
      description:
        "Design of overhead or underground lines to efficiently deliver energy over long distances.",
      imageUrl: ecard5, // Replace with actual image path
    },
  ];

  const servicesOffered = [
    {
      title:
        "Control Systems for Machines and Processes with PLC / SCADA Technology",
      imageUrl: ecard11, // Replace with actual image paths
    },
    {
      title: "Distributed Control Systems (DCS) for Process Automation",
      imageUrl: ecard22,
    },
    {
      title: "Remote Supervision and Control (Telecontrol)",
      imageUrl: ecard33,
    },
    {
      title: "Energy Management Systems with Supervision Software",
      imageUrl: ecard44,
    },
    {
      title:
        "Motor Control Center (MCC) with Relays, Softstarters, and Variators",
      imageUrl: ecard55,
    },
  ];

  return (
    <Fragment>
      <ServiceBanner
        serviceName={"Electromechanical, energy and automation"}
        background={background}
      >
        Powering innovation where
        <span className="hidden md:inline">
          {" "}
          <br />{" "}
        </span>{" "}
        precision meets performance.
      </ServiceBanner>
      <BreadCrumb page="Electromechanical, energy and automation" />

      <GraphRight className={"mt-8"} />

      <SliderSection
        heading={"Energy Solutions"}
        className={{
          heading: "md:!pl-0",
          container: "!mb-0",
          subSection: "md:!w-[80%]",
        }}
      >
        <DesktopCards cards={Solutions} />
        <MobileCards cards={Solutions} />
      </SliderSection>
      <GraphRight />

      <div className="relative w-full overflow-x-hidden">
        <div className="mx-auto">
          <div className="mx-auto pl-8 pb-8 md:w-[80%] md:pl-0">
            <H2>Industrial Automation and Process Control</H2>
            <p className="text-lg text-ANL-B">Services Offered</p>
          </div>
          <div className="hidden w-[90%] mx-auto mt-4 pb-4 md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {servicesOffered.map((service, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
              >
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <p className="text-center text-[12.57px] leading-[13.02px] text-ANL-R">
                    {service.title}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <SliderWrapper>
            {servicesOffered.map((service, index) => (
              <div
                key={index}
                className="block !m-4 bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
              >
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <p className="text-center text-[12.57px] leading-[13.02px] text-ANL-R">
                    {service.title}
                  </p>
                </div>
              </div>
            ))}
          </SliderWrapper>
        </div>
      </div>

      <div className="my-10 md:my-20 w-[80%] mx-auto pb-20">
        <H2 className={"mb-10 lg:p-0"}>
          Solutions for Gearless Mill Drive Motors (GMD’s)
        </H2>
        <div className="space-y-8">
          {serviceSectionsData.map((section, index) => (
            <ServiceSection
              key={index}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Electromachanical;
