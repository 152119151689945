import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ChevronLeft from "../assets/ChevLeft.svg";
import ChevronRight from "../assets/ChevRight.svg";
import RightSliderG from "../assets/images/SLIDER/RightSliderGradient.svg";
import LeftSliderG from "../assets/images/SLIDER/LeftSliderGradient.svg";

const ArrowLeft = (props) => (
  <img
    src={ChevronLeft}
    className="custom-arrow custom-prev z-40"
    onClick={props.onClick}
    alt="arrow"
  />
);
const ArrowRight = (props) => (
  <img
    src={ChevronRight}
    className="custom-arrow custom-next z-40"
    onClick={props.onClick}
    alt="arrow"
  />
);

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1, // Show one main slide at a time
  centerMode: true, // Center the slide
  centerPadding: "10px", // Adjust padding to show partial slides on both sides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "25%", // Show partial slides on mobile
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: "20%", // Narrower padding for smaller screens
      },
    },
  ],
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
  autoPlay: false,
};

export function SliderWrapper({ children }) {
  return (
    <div className="relative pt-5 md:m-0 md:hidden">
      <img src={RightSliderG} className="absolute left-0 top-0 h-full z-20" />
      <Slider {...sliderSettings}>{children}</Slider>
      <img src={LeftSliderG} className="absolute right-0 top-0 h-full z-20" />
    </div>
  );
}
