import React, { Fragment } from "react";
import "./minning.css";

import { GraphRight, GraphLeft, BreadCrumb } from "../../components/Section";

import background from "../../assets/images/minning.png";

import ServiceBanner from "../../components/ServiceBanner";
import { H2 } from "../../components/Section";
import { DesktopCards, MobileCards } from "../../components/Cards";
import {
  Analysis,
  DevelopmentFlow,
  Expertise,
  SliderSection,
} from "../../components/Mining";

import {
  section1Data,
  section2Data,
  section3Data,
  section4Data,
  section5Data,
  section6Data,
  section7Data
} from "./mining.data";

export default function Main() {
 
  const sections = [
    {
      title: "Technical Studies",
      points: [
        "Geology and exploration",
        "Mineral Resources",
        "Geometallurgy",
        "Technical Report NI 43-101 / JORC",
        "Mining Geology",
      ],
      description:
        "Studies with an integral vision to identify findings and opportunities in early stages, according to the reality of each Project or Mine.",
    },
    {
      title: "Reviews and Audit",
      points: ["High Level Review", "Audits", "Due Diligence"],
      description:
        "For reasons of certainty requirements and rigorous controls by investors, the procedures for estimating, classifying, and declaring Mining Resources are increasingly demanding. Therefore, having reviews at a high level or audits on these is crucial to ensure the results currently being carried out reflect this reality.",
    },
    {
      title: "Academic Support and Workshops",
      points: [
        "QA - QC",
        "Applied Geometallurgy",
        "Geostatistics",
        "Fundamentals for Mineral Resources",
      ],
      description:
        "Workshops aimed at companies or universities in order to contribute to the reduction of the gap between reality and the expected project level. With a multidisciplinary team focused on mining and consulting companies.",
    },
  ];



  return (
    <Fragment>
      <ServiceBanner serviceName={"Mining"} background={background}>
        Driving growth through innovation, efficiency, <br /> and responsible
        mining practices.
      </ServiceBanner>
      <BreadCrumb page="Mining" />
      <GraphRight className={"mt-8"} />
      <div className="w-[85%] mx-auto mb-8 md:w-[80%]">
        <H2>
          Expertise ins geology, geometallurgy,
          <br /> geothecnics and mining
        </H2>
        <Expertise />
      </div>
      <GraphLeft />
      <SliderSection heading="Open Pit Mining Planning">
        {/* Desktop grid view */}
        <DesktopCards
          cards={section1Data}
          cardSettings={{ className: "xl:w-[22.5%]", hideTitle: true }}
        />
        {/* Mobile view slider */}
        <MobileCards cards={section1Data} cardSettings={{ hideTitle: true }} />
      </SliderSection>
      <GraphRight />
      <SliderSection heading="Optimization of Mining System">
        {/* Desktop grid view */}
        <DesktopCards
          cards={section2Data}
          cardSettings={{ className: "xl:w-[22.5%]", hideTitle: true }}
        />
        {/* Mobile view slider */}
        <MobileCards cards={section2Data} cardSettings={{ hideTitle: true }} />
      </SliderSection>
      <GraphLeft />
      <SliderSection heading="Underground Mine Planning">
        {/* Desktop grid view */}
        <DesktopCards
          cards={section3Data}
          className={"w-[80%] mx-auto pb-4"}
          cardSettings={{ hideTitle: true }}
        />
        {/* Mobile view slider */}
        <MobileCards cards={section3Data} cardSettings={{ hideTitle: true }} />
      </SliderSection>
      <GraphRight />
      <SliderSection heading={"Underground Mine Design and Methods"}>
        {/* Desktop grid view */}
        <DesktopCards
          cards={section4Data}
          className={"w-[80%] mx-auto pb-4"}
        />
        {/* Mobile view slider */}
        <MobileCards cards={section4Data}  />
      </SliderSection>
      <GraphLeft />
      <div className="flex flex-col items-center p-8 md:my-16 overflow-x-auto">
        <H2 className={"mb-8"}>Quantitative Risk Analysis (QRA)</H2>
        <Analysis />
        <div className="circle-data mx-auto hidden">
          <div className="flex-row-ee">
            <div className="frame-b4">
              <div className="frame-b5">
                <span className="span-b6">
                  Modeling of <br /> factors affecting <br /> the mining plan
                </span>
              </div>
            </div>
            <div className="frame-b7">
              <div className="frame-b8">
                <span className="span-b9">
                  Risk estimation and <br /> degree of reliability <br /> of the
                  mining plan
                </span>
              </div>
            </div>
            <div className="vector" />
          </div>
          <div className="flex-row-efa">
            <div className="vector-ba" />
            <div className="vector-bb" />
          </div>
          <div className="flex-row">
            <div className="frame-bc">
              <div className="frame-bd">
                <span className="modeling-factors-mining">
                  Modeling of <br /> factors affecting <br /> the mining plan
                </span>
              </div>
            </div>
            <div className="frame-be">
              <div className="frame-bf">
                <span className="risk-estimation-reliability">
                  Risk estimation and <br /> degree of reliability <br /> of the
                  mining plan
                </span>
              </div>
            </div>
          </div>
          <div className="flex-row-c0">
            <div className="vector-c1" />
            <div className="vector-c2" />
            <div className="frame-c3">
              <div className="frame-c4">
                <span className="construction-mathematical-model">
                  Construction of the <br /> mathematical model <br /> for
                  quantitative rish <br />
                  analysis
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GraphRight />
      <SliderSection heading={"Project Evaluation"}>
        {/* Desktop grid view */}
        <DesktopCards cards={section5Data} className={"w-[80%] mx-auto pb-4"} />
        {/* Mobile view slider */}
        <MobileCards cards={section5Data} />
      </SliderSection>
      <GraphLeft />
      <SliderSection
        heading={
          "Geological Models - Estimation of Mineral Resources & Reserves"
        }
      >
        {/* Desktop grid view */}
        <DesktopCards
          cards={section6Data}
          className={"w-[80%] mx-auto pb-4"}
        />
        {/* Mobile view slider */}
        <MobileCards cards={section6Data} />
      </SliderSection>
      <GraphRight />
      <div className="container geology-wrapper p-6 space-y-8">
        <h2 className="text-2xl font-bold mb-4">Geology & Geometallurgy</h2>
        {sections.map((section, index) => (
          <div
            key={index}
            className="flex flex-cols md:flex-row items-center md:items-center space-y-4 md:space-y-0 md:space-x-6 border-b pb-4"
          >
            <div className="flex-1">
              <h3 className="text-sm md:text-lg font-semibold mb-2">
                {section.title}
              </h3>
              <ul className="list-disc geology-list list-inside space-y-1  md:text-sm text-gray-600">
                {section.points.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="flex-none">
              <span className="arrow">➔</span>
            </div>
            <div className="flex-1 geology-des bg-gray-100 p-4 rounded-md shadow  text-gray-600">
              {section.description}
            </div>
          </div>
        ))}
            
      </div>
      <div className="py-16 w-[80%] mx-auto">
        <h1 className="main-title text-ANL-BLACK lg:!text-[35px]">Geotechnics & Geomechanics Area</h1>
        <div className="section-title">
          Design of open pit slopes. Levels of conceptual, prefeasibility,
          operations, and closure studies
        </div>
        <ul className="list">
          <li>Geotechnical research and laboratory testing program.</li>
          <li>
            Geotechnical modeling (geological, structural, rock mass and
            hydrogeological model).
          </li>
          <li>
            Structural and resistance domain. Structural analysis and kinematic
            analysis.
          </li>
          <li>Analysis of rock massif: method of equilibrium limits.</li>
          <li>
            Sensitivity analysis of interramp slopes and generation of
            geotechnical sectors.
          </li>
          <li>Slope monitoring program and geotechnical risk assessment.</li>
        </ul>
        <h2 className="sub-title">
          Geotechnical studies: preparation of stability analysis
        </h2>
        <p>
          Includes stability analysis in waste deposits, tailing dams, leach
          piles, foundations of process plants. Levels of conceptual,
          prefeasibility, feasibility, operations, and closure studies.
        </p>
        <ol className="list1">
          <li>Program of geotechnical research and laboratory test.</li>
          <li>Physical stability analysis.</li>
          <li>Analysis of mining infrastructure foundations.</li>
          <li>Slope monitoring program.</li>
        </ol>
        <h2 className="sub-title">Underground geomechanics</h2>
        <ol className="list1">
          <li>Geotechnical research and laboratory testing program.</li>
          <li>Structural interpretation and analysis.</li>
          <li>
            Geotechnical modeling (geological, structural, rock mass, and
            hydrogeological model).
          </li>
          <li>Geomechanical zoning.</li>
          <li>Underground stability analysis.</li>
        </ol>
            
      </div>

      <GraphRight />
      <SliderSection heading={"Mining GIS Models"}>
        {/* Desktop grid view */}
        <DesktopCards cards={section7Data} cardSettings={{hideTitle:true}} className={"w-[80%] mx-auto pb-4"} />
        {/* Mobile view slider */}
        <MobileCards cards={section7Data} cardSettings={{hideTitle:true}} />
      </SliderSection>

      <div className="w-full relative overflow-hidden my-8">
        <div className="mx-auto md:w-[85%]">
          <H2 className={"my-auto pl-8 md:pl-16 md:pb-4"}>
            Software and Developments
          </H2>
          <DevelopmentFlow />
        </div>
      </div>
    </Fragment>
  );
}
