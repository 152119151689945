import { useMemo } from "react";
import { SliderWrapper } from "./Slider";

function ListDescription({ lists }) {
  return (
    <ul className="text-left ml-8 list-disc text-[10px] md:text-[0.81rem] text-ANL-R">
      {lists.map((l, index) => (
        <li key={index}>{l}</li>
      ))}
    </ul>
  );
}

export function Card({ solution, settings }) {
  const description = useMemo(() => {
    if (solution.description instanceof Array) {
      return <ListDescription lists={solution.description} />;
    }
    return (
      <p
        className="text-[0.9rem] leading-[1rem]"
        style={{ fontFamily: "AreaNormalTrialRegular" }}
      >
        {solution.description}
      </p>
    );
  }, [solution.description]);

  return (
    <div
      className={`flex flex-col w-full sm:w-[47.5%] lg:w-[30%] bg-white shadow-xl rounded-[17px] overflow-hidden ${settings?.className}`}
    >
      <img
        src={solution.imageUrl}
        alt={solution.title}
        className={`w-full md:h-[150px] ${
          settings?.hideTitle ? "h-[220px]" : "xl:h-[230px]"
        } object-cover`}
      />
      <div
        className="my-auto p-6 flex-1"
        style={{
          textAlign: "center",
        }}
      >
        {settings?.hideTitle ? (
          <></>
        ) : (
          <h3 className="text-[1.1rem] leading-[1.2rem] mb-2 h-fit text-ANL-EB">{solution.title}</h3>
        )}
        {description}
      </div>
    </div>
  );
}

export function SliderCard({ solution, index, settings }) {
  const description = useMemo(() => {
    if (solution.description instanceof Array) {
      return <ListDescription lists={solution.description} />;
    }
    return (
      <p className="text-black text-[9.28px] text-ANL-B m-auto">
        {solution.description || solution.title}
      </p>
    );
  }, [solution.description]);
  return (
    <div
      key={index}
      className={`overflow-hidden !rounded-[10px] shadow-lg m-2 h-full ${settings?.className}`}
    >
      <img
        src={solution.imageUrl}
        alt={solution.title}
        className={`w-full h-48 object-cover`}
      />
      <div
        className="flex flex-col p-4"
        style={{
          textAlign: "center",
        }}
      >
        {settings?.hideTitle ? (
          <></>
        ) : (
          <h3 className="hidden text-sm font-semibold mb-1">
            {solution.title}
          </h3>
        )}
        {description}
      </div>
    </div>
  );
}

export function MobileCards({ cards, cardSettings }) {
  return (
    <SliderWrapper>
      {cards.map((solution, index) => (
        <SliderCard
          settings={cardSettings}
          key={index}
          index={index}
          solution={solution}
        />
      ))}
    </SliderWrapper>
  );
}

export function DesktopCards({ cards, className, cardSettings }) {
  return (
    <div
      className={`hidden mt-8 mb-6 md:flex md:flex-wrap gap-8 px-8 justify-center ${className}`}
    >
      {cards.map((solution, index) => (
        <Card settings={cardSettings} key={index} solution={solution} />
      ))}
    </div>
  );
}
