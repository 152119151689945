import React from "react";
import background from "../assets/home/aboutus.png";
// import Graident from "../assets/home/HOME.png";

import "./responsive.css";
import IntroBanner, { IntroContent } from "../components/IntroBanner";

import HomeBottomEffectSVG from "../assets/home/HomeBottomEffect.svg";

const AboutUs = () => {
  return (
    <IntroBanner background={background} className="mb-auto !mt-[80px]" home={true}>
      <IntroContent className={"!py-28 !mb-auto !mt-0 md:!mb-auto md:!mt-0"}>
        <h1 className="text-white mb-8 text-[1.783rem] text-ANL-B md:text-[1.921rem]">
          About us
        </h1>
        <p className="max-w-4xl text-[0.91rem] leading-[1.054rem] mb-2 text-ANL-R">
          DARAT is a Saudi-based corporation dedicated to the Saudi 2030 vision,
          <span className="hidden md:inline">
            {" "}
            <br />{" "}
          </span>
          offering various integrated solutions that encompass top-tier services
          <span className="hidden md:inline">
            {" "}
            <br />{" "}
          </span>
          and high-end products.
        </p>
        <h2
          className="text-[1.404rem] text-ANL-R font-semibold mb-2"
        >
          Innovation and technology should simplify life.
        </h2>
        <p className="max-w-4xl text-[0.91rem] leading-[1.054rem] mb-2 text-ANL-R">
          We are a forerunner in the field of system integrators.
        </p>
        <p className="max-w-4xl text-[0.91rem] leading-[1.054rem] mb-2 text-ANL-R">
          We provide comprehensive solutions that meet the needs of our
          customers
          <span className="hidden md:inline">
            {" "}
            <br />{" "}
          </span>
          and have built strong ties with our government and private sector
          partners.
        </p>
        <p className="max-w-4xl text-[0.91rem] leading-[1.054rem] mb-2 text-ANL-R">
          We have internationally skilled business experts and engineers
          <span className="hidden md:inline">
            {" "}
            <br />{" "}
          </span>
          ready to provide exceptional services to clients in various
          industries.
        </p>
      </IntroContent>
      <img
          src={HomeBottomEffectSVG}
          alt="bottom effect"
          className="!h-fit w-[70%] aspect-auto my-4 md:hidden"
        />
    </IntroBanner>
  );
};

export default AboutUs;
