import React from "react";
import { Link } from "react-router-dom";

import IntroBanner, { IntroContent } from "../components/IntroBanner";

import background from "../assets/images/background.png";
import HomeBottomEffectSVG from "../assets/home/HomeBottomEffect.svg";

const Home = () => {
  return (
    <Link to="/services">
      {" "}
      <IntroBanner background={background} home={true}>
        <IntroContent>
          <h1 className="text-ANL-BLACK text-[1.784rem] leading-[1.849rem] md:text-[2.329rem] md:leading-[2.413rem]">
            GET THE LATEST IN TECHNOLOGY
          </h1>
          <h2 className="text-ANL-EB text-[1.42rem] leading-[1.471rem] md:text-[1.854rem] md:leading-[1.921rem] mt-2">
            FOR YOUR BUSINESS
          </h2>
          <p className="w-[293px] md:w-[579px] text-ANL-B text-[0.963rem] leading-[0.998] mt-6 md:text-[1.258rem] md:leading-[1.303rem]">
            DARAT is a Saudi-based corporation dedicated to the Saudi 2030
            vision, offering various integrated solutions that encompass
            top-tier services and high-end products.
          </p>
        </IntroContent>
        <img
          src={HomeBottomEffectSVG}
          alt="bottom effect"
          className="!h-fit w-[70%] aspect-auto my-4 md:hidden"
        />
      </IntroBanner>
    </Link>
  );
};

export default Home;
