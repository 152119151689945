import React, { Fragment } from "react";
import "./IT.css";
import "./responsive.css";
import background from "../assets/images/IT.png";
import placeholderImage from "../assets/images/automation.png";
import image1 from "../assets/images/security1.png"; // Replace with actual image paths
import image2 from "../assets/images/security2.png";
import image3 from "../assets/images/security3.png";
import image4 from "../assets/images/security4.png";
import image5 from "../assets/images/security5.png";
import image6 from "../assets/images/security6.png";
import image7 from "../assets/images/telecom4.png";
import image8 from "../assets/images/security8.png";
import image9 from "../assets/images/security9.png";
import image10 from "../assets/images/security10.jpg";
import image11 from "../assets/images/security11.png";
import image12 from "../assets/images/security12.jpg";
import image13 from "../assets/images/security13.avif";
import image14 from "../assets/images/security14.jpg";
import image15 from "../assets/images/security15.jpg";
import image16 from "../assets/images/security16.jpg";
import image17 from "../assets/images/security17.jpg";
import image18 from "../assets/images/security18.jpg";
import image19 from "../assets/images/security19.jpg";
import image20 from "../assets/images/security20.jpg";

import hardware1 from "../assets/images/hardware1.png"; // Replace with actual image paths
import hardware2 from "../assets/images/hardware2.png";
import hardware3 from "../assets/images/hardware3.png";
import hardware4 from "../assets/images/hardware4.png";
import hardware5 from "../assets/images/hardware5.png";
import hardware6 from "../assets/images/hardware6.png";
import integrated1 from "../assets/images/integrated1.png"; // Replace with actual image paths
import integrated2 from "../assets/images/integrated2.png";
import integrated3 from "../assets/images/integrated3.png";
import integrated4 from "../assets/images/integrated4.png";
import integrated5 from "../assets/images/integrated5.png";
import integrated6 from "../assets/images/integrated6.png";
import integrated8 from "../assets/images/integrated8.png";
import integrated9 from "../assets/images/integrated9.png";
import integrated10 from "../assets/images/integrated10.png"; // Replace with actual image paths
import integrated12 from "../assets/images/integrated12.png";
import integrated13 from "../assets/images/integrated13.png";
import integrated14 from "../assets/images/integrated14.png";
import integrated15 from "../assets/images/integrated15.png";
import integrated11 from "../assets/images/integrated11.png";
import integratedWifi from "../assets/images/integrated-wifi.png";
import it1 from "../assets/images/it1.png"; // Replace with actual image paths
import it2 from "../assets/images/it2.png";
import it3 from "../assets/images/it3.png";
import it4 from "../assets/images/it4.png";
import it5 from "../assets/images/it5.png";
import it6 from "../assets/images/it6.png";
import it7 from "../assets/images/it4.png";
import it8 from "../assets/images/it8.png";
import telecom1 from "../assets/images/telecom1.png";
import telecom2 from "../assets/images/telecom2.png";
import telecom3 from "../assets/images/telecom3.png";
import telecom4 from "../assets/images/telecom4.png";
import telecom5 from "../assets/images/telecom5.png";
import telecom6 from "../assets/images/telecom6.png";
import droneImage from "../assets/images/drones1.png";
import drone from "../assets/images/drone.png";

import ServiceBanner from "../components/ServiceBanner";
import {
  BreadCrumb,
  GraphLeft,
  GraphRight,
  InfoSection,
} from "../components/Section";

const solutions = [
  {
    title: "Electrical Work",
    description:
      "Ensure reliable and efficient power supply for your operations.",
    imageUrl: integrated1,
  },
  {
    title: "Network Testing & Commissioning",
    description:
      "We assess and deploy network infrastructure for optimal performance and connectivity.",
    imageUrl: integrated2,
  },
  {
    title: "Operator Training",
    description:
      "Equip your team with the knowledge and skills needed to maximize the potential of your technology investments.",
    imageUrl: integrated3,
  },
  {
    title: "Structured Cabling System",
    description:
      "Experience flawless data transmission and connectivity through our robust and organized cabling solutions.",
    imageUrl: integrated4,
  },
  {
    title: "IP Telephone & Networking",
    description:
      "Enhance communication efficiency and connectivity with our state-of-the-art IP telephone and networking solutions.",
    imageUrl: integrated5,
  },
  {
    title: "MATV & IPTV",
    description:
      "Deliver high-quality multimedia content to multiple screens with our MATV and IPTV systems.",
    imageUrl: integrated6,
  },
  {
    title: "Wireless Access Solutions",
    description:
      "Our innovative wireless access solutions offer seamless wireless connectivity and mobility.",
    imageUrl: integratedWifi,
  },
  {
    title: "CCTV & Access Control",
    description:
      "Protect your premises and control access with our cutting-edge CCTV and access control systems.",
    imageUrl: integrated8,
  },
  {
    title: "Queuing System",
    description:
      "Streamline customer flow and improve service efficiency with our intuitive and user-friendly queuing system.",
    imageUrl: integrated9,
  },
  // Additional 6 cards
  {
    title: "Master Clock System",
    description:
      "Precise timekeeping across your organization, accurate and synchronized.",
    imageUrl: integrated10,
  },
  {
    title: "Fire Alarm & Public Address System",
    description:
      "Safeguard lives and property with our reliable fire alarm and public address systems.",
    imageUrl: integrated11,
  },
  {
    title: "Building Management System",
    description:
      "Streamline customer flow and improve service efficiency with our intuitive and user-friendly queuing system.",
    imageUrl: integrated12,
  },
  {
    title: "UPS & Power Supply",
    description: "Uninterrupted power supply and protect your equipment.",
    imageUrl: integrated13,
  },
  {
    title: "Lighting Control Systems",
    description: "Energy efficiency and personalized lighting environments.",
    imageUrl: integrated14,
  },
  {
    title: "Nurse Call System",
    description:
      "Efficient communication and care coordination with our nurse call systems.",
    imageUrl: integrated15,
  },
];
const cards = [
  {
    title: "Microwave Communications",
    description:
      "Our microwave communication systems offer high-speed, point-to-point connectivity, enabling seamless data and voice transmission across vast distances.",
    imageUrl: telecom1, // Replace with actual image paths
  },
  {
    title: "Supply and Installation of Shelters",
    description:
      "We offer end-to-end solutions for supplying and installing shelters, providing a secure and controlled environment for your sensitive equipment.",
    imageUrl: telecom2,
  },
  {
    title: "Remote Monitoring Controller",
    description:
      "Enable real-time monitoring and control of your infrastructure from a centralized location. Manage your systems, optimize performance, and minimize downtime.",
    imageUrl: telecom3,
  },
  {
    title: "Self-Support Towers",
    description:
      "A sturdy and reliable infrastructure for various communication systems. It is designed for durability and stability and ensures excellent signal transmission, even in harsh environments.",
    imageUrl: telecom4,
  },
  {
    title: "Monopoles & Rooftop Mount",
    description:
      "Solutions for efficient and space-saving communication infrastructure. Our expert team designs and installs these structures, optimizing signal strength and coverage while minimizing visual impact.",
    imageUrl: telecom5,
  },
  {
    title: "Broadcast Towers",
    description:
      "Specifically designed to meet the demanding requirements of the broadcasting industry, you can ensure optimal signal quality, coverage, and reliability for your operations.",
    imageUrl: telecom6,
  },
];

const cardss = [
  {
    title: "Networking/Data Cabinets",
    description:
      "Our state-of-the-art networking and data cabinets are designed to provide a secure and organized space for your network equipment.",
    imageUrl: it1, // Replace with actual image paths
  },
  {
    title: "Fiber/UTP Patch Panels",
    description:
      "High-quality fiber and UTP patch panels enable a structured connection. These panels provide a central hub for connecting and managing your network cables, ensuring optimal performance and flexibility.",
    imageUrl: it2,
  },
  {
    title: "Fiber Optic Cables & UTP/Copper Cable",
    description:
      "Enable real-time monitoring and control of your infrastructure from a centralized location. Manage your systems, optimize performance, and minimize downtime.",
    imageUrl: it3,
  },
  {
    title: "Ducts & Sub-Ducts",
    description:
      "Provide a secure pathway for routing your network cables. Designed for durability and ease of installation, it ensures excellent cable protection, reducing the risk of damage and signal interference.",
    imageUrl: it4,
  },
  {
    title: "Manhole & Handholes",
    description:
      "Serve as access points to your underground network infrastructure. They provide convenient entry for maintenance and upgrades, ensuring easy access and efficient management.",
    imageUrl: it5,
  },
  {
    title: "Conduit System & Cable Tray System",
    description:
      "Provide a reliable and organized solution for managing your network cables. Our cable tray system facilitates installation and protects cabling, simplifying installation and maintenance processes.",
    imageUrl: it6,
  },
  {
    title: "MDF (Main Distribution Frame)",
    description:
      "Serves as the central point for distributing network connectivity. MDFs provide a reliable foundation for routing and organizing your cabling, ensuring excellent performance and reducing network disruptions.",
    imageUrl: it7,
  },
  {
    title: "Grounding & Bonding",
    description:
      "We prioritize the grounding and bonding of infrastructure, ensuring a stable foundation for all network components. Proper grounding enhances safety, performance, and long-term reliability of your systems.",
    imageUrl: it8,
  },
];

const hardwareSolutions = [
  {
    title: "Input",
    description:
      "Enhance productivity with intuitive input devices like ergonomic keyboards and precision mice, touchpads, barcode scanners, RFIDs.",
    imageUrl: hardware1,
  },
  {
    title: "Output",
    description:
      "Immerse yourself in stunning visuals with highresolution monitors and immersive audio systems.",
    imageUrl: hardware2,
  },
  {
    title: "Processing",
    description:
      "Power through demanding tasks with cuttingedge CPUs and GPUs for prime performance. We also offer Application Specific Integrated Circuits (ASIC).",
    imageUrl: hardware3,
  },
  {
    title: "Storage",
    description:
      "Keep your data secure and easily accessible with reliable storage solutions like lightning-fast Solid State Drives (SSD), Hard Disk drives (HDD), USB flash drives, and expandable NAS systems.",
    imageUrl: hardware4,
  },
  {
    title: "Communications",
    description:
      "Stay connected and collaborate effortlessly with enterprise-grade networking equipment and robust communication tools. Such as Network Interface Cards, Routers, Switches, Modems, and Wireless Access Points",
    imageUrl: hardware5,
  },
  {
    title: "Internet of Things (IoT)",
    description:
      "Embrace the future of connectivity with IoT devices that automate processes, collect valuable data, and create smarter environments with out Smart Thermostats, Home security systems, connected appliances, and industrial sensors.",
    imageUrl: hardware6,
  },
  // Additional cards can be added here
];

const cardsss = [
  {
    title: "Pentesting",
    description:
      "Thorough penetration testing to identify vulnerabilities and strengthen the security of your systems.",
    imageUrl: image1,
  },
  {
    title: "Social engineering",
    description:
      "Comprehensive assessments and training to protect from human-based security threats.",
    imageUrl: image2,
  },
  {
    title: "Critical infrastructure security",
    description:
      "Robust solutions to safeguard critical infrastructures against cyber threats.",
    imageUrl: image3,
  },
  {
    title: "Application security",
    description:
      "Protect your software and applications from unauthorized access.",
    imageUrl: image4,
  },
  {
    title: "Network security",
    description:
      "Safeguard your network infrastructure against unauthorized access and data breaches.",
    imageUrl: image5,
  },
  {
    title: "Cloud security",
    description:
      "Ensure your data’s privacy, integrity, and availability in cloud environments.",
    imageUrl: image6,
  },
  {
    title: "Internet of Things (IoT) security",
    description:
      "Protect your devices and networks, including all interconnected devices and protocols, to guard against unauthorized access or control.",
    imageUrl: image7,
  },
  {
    title: "Fencing systems & security lighting",
    description:
      "Provide physical barrier and enhanced visibility to protect your premises.",
    imageUrl: image8,
  },
  {
    title: "Gates, gate blockers & barriers",
    description:
      "Control access and prevent unauthorized entry to your facility.",
    imageUrl: image9,
  },
  {
    imageUrl: image10,
    title: "Under-vehicle detectors",
    description:
      "Advanced technology to scan vehicles for hidden threats, ensuring comprehensive security.",
  },
  {
    imageUrl: image11,
    title: "Walkthrough metal detectors",
    description:
      "Provide effective screening for detecting metal objects and enhancing security protocols.",
  },
  {
    imageUrl: image12,
    title: "Explosive and narcotics detections",
    description:
      "Identify and mitigate potential threats, ensuring a safe environment.",
  },
  {
    imageUrl: image13,
    title: "X-ray detection & CT inspection equipment",
    description:
      "Enable thorough screening and detection of concealed items, ensuring maximum security.",
  },
  {
    imageUrl: image14,
    title: "Access control system",
    description:
      "Provide secure entry and exit points, granting authorized personnel access while restricting unauthorized individuals.",
  },
  {
    imageUrl: image15,
    title: "Automatic License Plate Recognition System",
    description: "Accurately capture and identify license plate information.",
  },
  {
    imageUrl: image16,
    title: "ID Management System",
    description:
      "Efficient and secure management of personnel identification, ensuring proper access and control.",
  },
  {
    imageUrl: image17,
    title: "Intrusion Detection & Assessment System",
    description:
      "Advanced sensors and monitoring to detect and respond to unauthorized access or security breaches.",
  },
  {
    imageUrl: image18,
    title: "Video Assessment & Surveillance System",
    description:
      "Round-the-clock monitoring, real-time video analytics, and remote access for effective security surveillance.",
  },
  {
    title: "Plant Control Room",
    description:
      "Provides centralized monitoring and control of security systems.",
    imageUrl: image19,
  },
  {
    title: "Security Control Center",
    description:
      "Central hub for monitoring and managing security operations, enabling quick response to security incidents and ensuring a safe environment.",
    imageUrl: image20,
  },
];

export default function IT() {
  return (
    <Fragment>
      <ServiceBanner serviceName="IT" background={background}>
        Driving the future with seamless{" "}
        <span className="hidden md:inline">
          {" "}
          <br />{" "}
        </span>
        connectivity and cutting-edge solutions.
      </ServiceBanner>
      <BreadCrumb page={"IT"} />
      <GraphRight />
      <InfoSection heading={"Telecom Services"} cards={cards} />
      <GraphLeft className={"mb-4"} />
      <InfoSection
        heading={"IT Passive solutions (OSP-ISP/PDS)"}
        cards={cardss}
      />
      <GraphLeft className={"!my-4"} />
      <InfoSection
        heading={" Integrated Communication Solutions"}
        cards={solutions}
      />
      <GraphRight />
      <InfoSection
        heading={"Hardware Solutions"}
        description={
          <div className="px-6 md:px-0">
            <p className="block my-4 text-[1.15rem] leading-[1.35rem] text-ANL-B">
              Darat offers a range of innovative hardware solutions, including
              cutting-edge input devices like advanced barcode scanners for
              seamless inventory management, state-of-theart output devices,
              high-resolution projectors that bring presentations to life, and
              powerful processing units like our custom-designed ASICs that
              accelerate data processing.
            </p>
            <p className="block my-4 text-[1.15rem] leading-[1.35rem] text-ANL-B">
              Reliable storage solutions like lightning-fast SSDs for secure and
              efficient data management, communication tools such as high-speed
              routers and switches that ensure flawless connectivity, and
              groundbreaking IoT devices that optimize energy usage and
              connected sensors that enable real-time monitoring and analysis
              for smarter decision-making
            </p>
          </div>
        }
        cards={hardwareSolutions}
      />
      <GraphLeft className={"md:my-6"} />
      <div className="w-full my-4">
        <div className="content-container w-[80%] mx-auto">
          <div className="text-content">
            <h2 className="it-dron mb-4">Drones</h2>
            <p className="it-dronp">
              We offer a range of advanced drones that revolutionize various
              industries, including aerial
              <strong> photography </strong> and <strong> videography</strong>,
              where our high-resolution camera-equipped drones capture
              breathtaking images and stunning videos from unique perspectives.
            </p>
            <p className="it-dronp">
              Our professional-grade drones provide accurate data collection and
              mapping capabilities surveying and mapping, enabling efficient
              land surveys and
              <strong> infrastructure inspections</strong>.
            </p>
            <p className="it-dronp">
              For <strong>agricultural applications</strong>, our agricultural
              drones with multispectral sensors optimize crop management by
              providing valuable insights into crop health and monitoring field
              conditions.
            </p>
            <p className="it-dronp">
              In the <strong>delivery and logistics</strong> sector, our
              delivery drones offer efficient and speedy transportation of
              goods, enabling timely deliveries in urban and remote areas.
            </p>
            <p className="it-dronp">
              Lastly, in <strong>search and rescue operations</strong>, our
              rugged and versatile drones equipped with thermal imaging cameras
              aid in locating missing persons and assessing disaster-stricken
              areas.
            </p>
          </div>
          <div className="image-content">
            <img src={drone} alt="Drones" className="drones-image" />
          </div>
        </div>
      </div>

      <GraphRight />

      <div className="!w-[80%] mx-auto md:my-8">
        <h1 className=" main-margin it-dron !mt-8 mb-4">System Automation</h1>

        <div className="w-full mx-auto flex flex-col gap-4 mb-4 md:flex-row md:gap-8 md:mb-8">
          <div className="w-full md:w-1/2 flex flex-col gap-2">
            <p className="it-auto">
              Darat offers comprehensive services; conceptual design ensuring
              our client's automation needs are met through innovative and
              efficient solutions. We offer multi-discipline detailed design and
              specification, guaranteeing seamless integration for optimized
              performance.
            </p>

            <p className="it-auto">
              We are proficient in programming distributed control systems
              (DCS), remote terminal units (RTU), and supervisory control and
              data acquisition (SCADA) systems, enabling centralized control and
              monitoring of industrial processes.
            </p>

            <p className="it-auto">
              Our grid automation solutions encompass advanced grid
              communication, metering infrastructure, and energy optimization,
              empowering our clients to enhance grid efficiency and maximize
              energy utilization.
            </p>

            <p className="it-auto">
              In the delivery and logistics sector, our delivery drones offer
              efficient and speedy transportation of goods, enabling timely
              deliveries in urban and remote areas.
            </p>
          </div>
          <img
            src={placeholderImage}
            alt=""
            className="w-full h-fit md:h-full md:w-1/2 rounded-[17px]"
          />
        </div>


        <div className="flex flex-col-reverse md:flex-row gap-8 my-8">
          <img
            style={{ borderRadius: "17px" }}
            className="w-full h-fit md:h-full md:w-1/2 rounded-[17px]"
            src={placeholderImage}
            alt="Automation system 2"
          />

          <div className="w-full md:w-1/2 flex flex-col gap-2">
            <p className="it-auto">
              <strong> Industrial communication gateways </strong> and protocol
              converters in hardware and software1 enable excellent
              communication between different devices and systems, ensuring
              interoperability and smooth operation.
            </p>

            <p className="it-auto">
              <strong> Station Plant Information System (SPIS) </strong> and
              Data Retrieval Station Evaluation Panel (DTR/SEP) provides a
              central platform for seamless communication and automation across
              all processes. Our Substation Automation System (SAS) enhances
              reliable remote management.
            </p>

            <p className="it-auto">
              We offer comprehensive installation and commissioning services,
              including pre-commissioning, construction supervision, and
              meticulous testing and inspection, guaranteeing our client’s
              successful implementation and seamless operation of automation
              systems.
            </p>
          </div>
        </div>

      </div>
      <InfoSection heading={"Security System"} cards={cardsss} />

      <div className="w-[70%] py-10 mx-auto">
        <h2
          className="text-[2.2rem]"
          style={{ fontFamily: "AreaNormalTrialBlack" }}
        >
          Drones
        </h2>
        <div className="w-full flex flex-col md:flex-row gap-4 mx-auto">
          {/* Text Section */}
          <div className="flex-1 py-4">
            <p
              className="text-black drone-text mb-4"
              style={{ fontFamily: "AreaNormalTrialRegular" }}
            >
              At <b>Darat</b>, we are experts in uncovering operational failures
              and resolving them swiftly, ensuring smooth and efficient business
              operations.
            </p>
            <p
              className="text-black drone-text mb-4"
              style={{ fontFamily: "AreaNormalTrialRegular" }}
            >
              Through our diligent system maintenance practices, we not only
              enhance your organization’s overall efficiency but also save
              <b> valuable time</b> and <b>resources</b> associated with system
              management and production, enabling you to focus on what truly
              matters – driving growth and success.
            </p>
            <h3 style={{ fontFamily: "AreaNormalTrialBlack" }}>
              Maintenance operations
            </h3>
            <ul
              style={{ fontFamily: "AreaNormalTrialRegular" }}
              className="text-black mt-2 list-disc drone-text list-inside "
            >
              <li>Preventive</li>
              <li>Corrective</li>
              <li>Complex maintenance</li>
            </ul>
          </div>

          {/* Image Section */}
          <div style={{ width: "40%" }} className="dron-image">
            <img
              src={droneImage}
              alt="Maintenance worker with drone equipment"
              className="rounded-lg drone-image-2 shadow-lg w-full h-auto"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
