import { Link } from "react-router-dom";
import group from "../assets/images/Group 145.png";
import group2 from "../assets/images/Group 146.png";
import { DesktopCards, MobileCards } from "./Cards";

export function GraphRight({ className }) {
  return (
    <img
      className={`w-[85%] ml-auto lg:w-auto ${className}`}
      src={group}
      alt=""
    />
  );
}
export function GraphLeft({ className }) {
  return (
    <img
      className={`w-[85%] mr-auto lg:w-auto ${className}`}
      src={group2}
      alt=""
    />
  );
}

export function BreadCrumb({ page }) {
  return (
    <p className="w-[90%] text-ANL-EL font-[100] mt-8 mx-auto flex gap-1">
      <Link to={"/"}>Home</Link>
      <span>{">"}</span>
      <Link to={"/services"}>Services</Link>
      <span>{">"}</span>
      <Link>{page}</Link>
    </p>
  );
}

export function H2({ children, className }) {
  return (
    <h2
      className={`text-[1.304rem] leading-[1.351rem] md:text-[2.227rem] md:leading-[2.307rem] service-solution ${className}`}
      style={{ fontFamily: "AreaNormalTrialBlack" }}
    >
      {children}
    </h2>
  );
}

export function InfoSection({ cards, heading, description, className, desktopClassName }) {
  return (
    <div className="w-full relative overflow-hidden">
      <div className={`w-full md:w-[70%] mx-auto py-2 ${className}`}>
        <H2 className={"mx-5 md:mx-0"}>{heading}</H2>
        {description}
        {/* Desktop  view */}
        <DesktopCards cards={cards} className={desktopClassName} />
        {/*Mobile grid view */}
        <MobileCards cards={cards} />
      </div>
    </div>
  );
}

// ServiceSection.js
export function ServiceSection({ title, items }) {
  return (
    <div>
      <h3 className="text-ANL-EB text-[10.61px] leading-[12px] md:text-[22.4px] md:leading-[23px] mb-2">
        {title}
      </h3>
      <ul className="list-disc">
        {items.map((item, index) => (
          <li
            className="ml-6 mt-[20px] text-ANL-R md:text-[1.1rem] md:leading-[1.199rem]"
            key={index}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
