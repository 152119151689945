import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

export function SocialIcon({ className, iconClassName }) {
  return (
    <div className={`flex space-x-4 ${className}`}>
      <a href="https://www.instagram.com/darat.ksa/" className={`text-white text-xl ${iconClassName}`}>
        <FaInstagram />
      </a>
      <a href="https://www.tiktok.com/@daratksa?lang=en" className={`text-white text-xl ${iconClassName}`}>
        <FaTiktok />
      </a>
      <a href="https://www.linkedin.com/company/daratechsol" className={`text-white text-xl ${iconClassName}`}>
        <FaLinkedinIn />
      </a>
    </div>
  );
}

export default function Footer() {
  return (
    <footer className="relative z-40 bg-[#191919] text-white p-4 flex justify-center  md:justify-between md:!px-20">
      <p className="text-[0.794rem] text-center text-ANL-EL font-[200] my-auto md:text-left">
        &copy; 2024 Darat Contracting Company
      </p>
      <SocialIcon className={"social-media"} />
    </footer>
  );
}
