//SECTION 1 DATA
import open1 from "../../assets/images/open1.png";
import open2 from "../../assets/images/open2.png";
import open3 from "../../assets/images/open3.png";
import open4 from "../../assets/images/open4.png";
import open5 from "../../assets/images/open5.png";
import open6 from "../../assets/images/open6.png";
import open7 from "../../assets/images/open7.png";
import open8 from "../../assets/images/open8.png";
import open9 from "../../assets/images/open9.png";
import open10 from "../../assets/images/open10.png";
import open11 from "../../assets/images/open11.png";
//SECTION 2 DATA
import optimization1 from "../../assets/images/optimization1.png";
import optimization2 from "../../assets/images/optimization2.png";
import optimization3 from "../../assets/images/optimization3.png";
import optimization4 from "../../assets/images/optimization4.png";
import optimization5 from "../../assets/images/optimization5.png";
import optimization6 from "../../assets/images/optimization6.png";
import optimization7 from "../../assets/images/optimization7.png";
//SECTION 3 DATA
import mine1 from "../../assets/images/mine1.png";
import mine2 from "../../assets/images/mine2.png";
import mine3 from "../../assets/images/mine3.png";
import mine4 from "../../assets/images/mine4.png";
import mine5 from "../../assets/images/mine5.png";
import mine6 from "../../assets/images/mine6.png";
import mine7 from "../../assets/images/mine7.png";
import mine8 from "../../assets/images/mine8.png";
import mine9 from "../../assets/images/mine9.png";
import mine10 from "../../assets/images/mine10.png";
import mine11 from "../../assets/images/mine11.png";
import mine12 from "../../assets/images/mine12.png";
import mine13 from "../../assets/images/mine13.png";
import mine14 from "../../assets/images/mine14.png";
//SECTION 4 DATA
import method1 from "../../assets/images/method1.png";
import method2 from "../../assets/images/method2.png";
import method3 from "../../assets/images/method3.png";
//SECTION 5 DATA
import project1 from "../../assets/images/project1.png";
import project2 from "../../assets/images/project2.png";
import project3 from "../../assets/images/project3.png";
import project4 from "../../assets/images/project4.png";
import project5 from "../../assets/images/project5.png";
import project6 from "../../assets/images/project6.png";
//SECTION 6 DATA
import modal1 from "../../assets/images/modal1.png";
import modal2 from "../../assets/images/modal2.png";
import modal3 from "../../assets/images/modal3.png";
import modal4 from "../../assets/images/modal4.png";
import modal5 from "../../assets/images/modal5.png";
import modal6 from "../../assets/images/modal6.png";
import modal7 from "../../assets/images/modal7.png";
import modal8 from "../../assets/images/modal8.png";
import modal9 from "../../assets/images/modal9.png";
//SECTION 7 DATA
import minning1 from "../../assets/images/minnings1.png";
import minning2 from "../../assets/images/minnings2.png";
import minning3 from "../../assets/images/minnings3.png";
import minning31 from "../../assets/images/minings3.1.png";
import minning4 from "../../assets/images/minnings4.png";
import minning6 from "../../assets/images/minnings6.png";
import minning8 from "../../assets/images/minnings8.png";
import minning9 from "../../assets/images/minnings9.png";
import minning71 from "../../assets/images/minings7.1.png";

export const section1Data = [
  {
    imageUrl: open1,
    title: "Strategic planning and growth",
    description:
      "Strategic planning and growth path to capture economic value.",
  },
  {
    imageUrl: open2,
    title: "Open pit mine phases designs",
    description:
      "Open pit mine phases designs at different levels of accuracy.",
  },
  {
    imageUrl: open3,
    title: "Haulage and access systems",
    description:
      "Design and comparison of haulage and access systems in subway mines.",
  },
  {
    imageUrl: open4,
    title: "Mining sequence planning",
    description: "Mining sequence and long and short-term production planning.",
  },
  {
    imageUrl: open5,
    title: "Estimate of mining equipment fleet",
    description: "Estimate of mining equipment fleet, CAPEX and OPEX.",
  },
  {
    imageUrl: open6,
    title: "Mineral Reserves estimation",
    description: "Estimation and certification of Mineral Reserves (GPI).",
  },
  {
    imageUrl: open7,
    title: "Business planning support",
    description: "Business planning and management support systems.",
  },
  {
    imageUrl: open8,
    title: "Graphic tool for planning",
    description:
      "Creation of graphic tool for integrated planning and decision making.",
  },
  {
    imageUrl: open9,
    title: "Mina Planning Parameters",
    description: "Analysis of inputs and Mina Planning Parameters.",
  },
  {
    imageUrl: open10,
    title: "CAD and 3D systems",
    description: "CAD and 3D systems for support analysis and mining planning.",
  },
  {
    imageUrl: open11,
    title: "Data processing",
    description: 'Data processing: "Data Exploratory Technique".',
  },
];

export const section2Data = [
  {
    imageUrl: optimization1,
    title: "Integrated Mine - Plant and Mine Dump Capacity Optimization",
    description:
      "Integrated Mine - Plant and Mine - Dump Capacity Optimization (Mining sequence and strategic filling of dumps).",
  },
  {
    imageUrl: optimization2,
    title: "Congestion and Operational Risk Analysis",
    description:
      "Congestion and Operational Risk Analysis - Traffiic Optimization at Ramps and Accesses.",
  },
  {
    imageUrl: optimization3,
    title:
      "Diagnosis of the Current Mining Optimization and Operational System",
    description:
      "Diagnosis of the Current Mining Optimization and Operational System.",
  },
  {
    imageUrl: optimization4,
    title: "Generation and Execution of Ideas to Improve the Current Process",
    description:
      "Generation and Execution of Ideas to Improve the Current Process.",
  },
  {
    imageUrl: optimization5,
    title: "Consulting and Modeling for Mining Simulation",
    description:
      "Consulting and Modeling for Mining Simulation (Loading System - Haulage - Maintenance of Roads / Auxiliary Equipment).",
  },
  {
    imageUrl: optimization6,
    title: "Strategic Mining Plans and Cut-Off Grade Optimization",
    description:
      "Strategic Mining Plans and Cut-off Grade Optimization for the Quest for Mining Asset Value.",
  },
  {
    imageUrl: optimization7,
    title: "Material Haulage Systems",
    description:
      "Material Haulage Systems Optimizaition Studies (IPCC, autonomous trucks, trolley assistance high slope belts, among others).",
  },
];

export const section3Data = [
  {
    imageUrl: mine1,
    title: "Selection of Mining Method",
    description: "Selection of Mining Method (Quantitative/Qualitative).",
  },
  {
    imageUrl: mine2,
    title: "Knowledge and Benchmarking",
    description:
      "Knowledge and bechmarking (block caving, room and pillar, shrikage stoping, cut and fill, sub level stoping and caving).",
  },
  {
    imageUrl: mine3,
    title: "Analysis and Optimization",
    description:
      "Analysis and optimization of basic underground mining operations.",
  },
  {
    imageUrl: mine4,
    title: "Underground Mining Plan",
    description:
      "Underground mining plan control (block model, mining system, dilution).",
  },
  {
    imageUrl: mine5,
    title: "Material Handling",
    description: "Material Handing: Loading and Haulage.",
  },
  {
    imageUrl: mine6,
    title: "Auxiliary Operations",
    description:
      "Ancillary operations: Ventilation, scaling, fortifications, others.",
  },
  {
    imageUrl: mine7,
    title: "Estimation of Mineral Resources",
    description: "Estimation of Mineral Reserves (QP).",
  },
  {
    imageUrl: mine8,
    title: "Underground Mining Plan",
    description: "Underground Mining Plan.",
  },
  {
    imageUrl: mine9,
    title: "Planning Parameters",
    description: "Planning Parameter.",
  },
  {
    imageUrl: mine10,
    title: "Methods of Underground Mining",
    description: "Methods of Underground Mining",
  },
  {
    imageUrl: mine11,
    title: "Cut-Off Grade - Dilution",
    description: "Cut-Off Grade - Dilution",
  },
  {
    imageUrl: mine12,
    title: "Mining Recovery",
    description: "Mining Recovery",
  },
  {
    imageUrl: mine13,
    title: "Production Programming and Execution",
    description: "Production Program and Economy Evalution.",
  },
  {
    imageUrl: mine14,
    title: "Design and Comparison",
    description:
      "Design and comparison of haulage and access systems in subway mines.",
  },
];

export const section4Data = [
  {
    title: "Sub Level Stoping",
    description: [
      "Design Criteria",
      "Selection of Stope",
      "Ore Extraction",
      "Backfill",
      "Drill and Blast Design",
      "Production Capacity",
      "Ore Handling Design",
      "Engineering Design",
    ],
    imageUrl: method1,
  },
  {
    title: "Sub Level Caving",
    description: [
      "Design Criteria",
      "Selection of Blocked Orebody",
      "Development & Drilling",
      "Drill and Blast Design",
      "Loading and Haulage",
      "Production Capacity",
      "Ore Handling Design",
      "Engineering Design",
    ],
    imageUrl: method2,
  },
  {
    title: "Block Caving",
    description: [
      "Design Criteria",
      "Selection of Blocked Orebody",
      "Column Caving Layout",
      "Gravity Flow of Ore",
      "Draw Point Spacing",
      "Production Capacity",
      "Material Handling Design",
      "Engineering Design",
    ],
    imageUrl: method3,
  },
];

export const section5Data = [
  {
    imageUrl: project1,
    description:
      "Project Evaluation: PEA - Conceptual, Pre-Feasibility and Feasibility",
  },
  {
    imageUrl: project2,
    description: "Characterization of the ore body from a value standpoint",
  },
  {
    imageUrl: project3,
    description: "Estimation of mineral resources and reserves",
  },
  {
    imageUrl: project4,
    description: "Technical reports - Open pit and underground mining QPs",
  },
  {
    imageUrl: project5,
    description: "Technical management and research consulting",
  },
  {
    imageUrl: project6,
    description: "Engineering and resource management services",
  },
];

export const section6Data = [
  {
    imageUrl: modal1,
    description: "Integration of topographic information to the national network.",
  },
  {
    imageUrl: modal2,
    description: "Information unification and advanced database management.",
  },
  {
    imageUrl: modal3,
    description:
      "Review of QA/QC procedures, Gathering of Findings and Opportunities.",
  },
  {
    imageUrl: modal4,
    description:
      "Exploratory Analysis of Uni and Multivariable Information. Data Science for Big Data Jobs.",
  },
  {
    imageUrl: modal5,
    description:
      "Structural geological modeling and different types of domains. Geo Metallurgical models focused on recovery calculations: R. Mass - Modifying factors.",
  },
  {
    imageUrl: modal6,
    description:
      "Calculation of the Cut Off and/or NSR. Machine learning analysis for geology. Geo-metallurgy and geostatistical considerations.",
  },
  {
    imageUrl: modal7,
    description:
      "Development of Jorc-type documents: NI-43101, SKR500 among others. Preparation and development of chapters in this area for Project level or LOM.",
  },
  {
    imageUrl: modal8,
    description:
      "Estimation of Resources and Reserves using Geostatistical techniques such as others such as KOR.",
  },
  {
    imageUrl: modal9,
    description:
      "Declaration of Resources and Reserves by Audits and Expert Reviews focused on the Review of Resources and Reserves by Specialty GPs.",
  },
];

export const section7Data = [
  {
    imageUrl: minning1,
    title: "Integration of topographic information into the national network",
    description:
      "Integration of topographic information into the national network.",
  },
  {
    imageUrl: minning2,
    title: "Unification of graphic information in a database",
    description: "Unification of graphic information in a database.",
  },
  {
    imageUrl: minning31,
    title: "Graphic systems for property security evaluation",
    description: "Graphic systems for property security evaluation.",
  },
  {
    imageUrl: minning3,
    title: "Graphic systems for emergency response",
    description: "Graphic systems for emergency response.",
  },
  {
    imageUrl: minning4,
    title: "Characterization of surface land integrating parameters",
    description:
      "Characterizacion of sufrace lands (neighboring properties, extension of concessions, hydrogeology, hydrography, potencial for landfills, among others)",
  },
  {
    imageUrl: minning6,
    title: "Updating in systems and use of coordinates and datums",
    description:
      "Updating in systems and use of coordinates and datums (Training included).",
  },
  {
    imageUrl: minning71,
    title: "Web plans",
    description: "Web plans",
  },
  {
    imageUrl: minning8,
    title: "Application of satellite images",
    description: "Application of satellite images",
  },
  {
    imageUrl: minning9,
    title: "Upgrades in new technologies for data acquisition",
    description: "Upgrades in new technologies for data acquisition and use.",
  },
];